<template>
    <div class="home">
        <div class="home_top">
            <Banner :images="images" @toShowContext="toShowContext" />
        </div>

        <div class="home_menu">
            <div class="home_menucontainer">
                <div class="home_card">
                    <div class="home_title">平台服务</div>
                    <div class="home_items">
                        <div v-on:click="toItemPage(1)" class="home_item">
                            <div class="home_item_container">
                                <div>
                                    <img src="assets/shebeiweixiu.png"/>
                                </div>
                                <span>设备维修</span>
                            </div>
                        </div>
                        <div v-on:click="toItemPage(2)" class="home_item">
                            <div class="home_item_container">
                                <div>
                                    <img src="assets/shebeibaoyang.png"/>
                                </div>
                                <span>设备保养</span>
                            </div>
                        </div>
                        <div v-on:click="toItemPage(3)" class="home_item">
                            <div class="home_item_container">
                                <div>
                                    <img src="assets/shebeigaizao.png"/>
                                </div>
                                <span>设备改造</span>
                            </div>
                        </div>
                    </div>
                    <div class="home_items home_card_item_letterspace">
                        <div v-on:click="toItemPage(5)" class="home_item">
                            <div class="home_item_container">
                                <div>
                                    <img src="assets/xiangmuwaibao.png"/>
                                </div>
                                <span>项目外包</span>
                            </div>
                        </div>
                        <div v-on:click="toItemPage(4)" class="home_item">
                            <div class="home_item_container">
                                <div>
                                    <img src="assets/xinjinshebei.png"/>
                                </div>
                                <span>新进设备</span>
                            </div>
                        </div>
                        <div class="home_item">
                            <div class="home_item_container home_clear">
                                <div></div>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="home_card home_card_letterspace">
                    <div class="home_title">其他服务</div>
                    <div class="home_items">
                        <div class="home_item" v-on:click="toPx">
                            <div class="home_item_container">
                                <div>
                                    <img src="assets/jinnegpeixun.png"/>
                                </div>
                                <span>技能培训</span>
                            </div>
                        </div>
                        <div class="home_item" @click="toBeiPinBeiJian">
                            <div class="home_item_container">
                                <div>
                                    <img src="assets/beipinbeijian.png"/>
                                </div>
                                <span>备品备件</span>
                            </div>
                        </div>
                        <div class="home_item" v-on:click="toHezuo">
                            <div class="home_item_container">
                                <div>
                                    <img src="assets/hezuohuoban.png"/>
                                </div>
                                <span>合作伙伴</span>
                            </div>
                        </div>
                    </div>
                    <div class="home_items home_card_item_letterspace">
                        <div class="home_item" @click="toCase">
                            <div class="home_item_container">
                                <div>
                                    <img src="assets/anlifenxiang.png"/>
                                </div>
                                <span>案例分享</span>
                            </div>
                        </div>
                        <div class="home_item">
                            <div class="home_item_container home_clear">
                                <div>
                                </div>
                                <span></span>
                            </div>
                        </div>
                        <div class="home_item">
                            <div class="home_item_container home_clear">
                                <div>
                                </div>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    document.title="篱笆匠人"
    import Banner from "../xyg/banner/Banner";
    import Vue from 'vue';
    export default {
        name: "Home",
        props:{
            rule:String
        },
        components:{
            Banner,
        },
        data(){
            return {
                images:[]
            }
        },
        mounted(){
            this.init();
        },
        methods:{
            init(){
                //加载banner
                let url="/api/banner";
                this.$post(url,null)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            this.images=rsp.data;

                            // this.wx.previewImage({
                            //     current:this.images[0].picUrl,
                            //     urls:[this.images[0].picUrl,this.images[1].picUrl,this.images[2].picUrl]
                            // });
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    });
            },
            toItemPage(type){
                this.$router.push({path:'/ReqList',name:'ReqList',query:{
                     cardType:type
                    }
                })
            },
            toHezuo(){
                this.$router.push({path:'/hezuo'})
            },
            toBeiPinBeiJian(){
                this.$router.push({path:'/bpbj'})
            },
            toPx(){
                this.$router.push({path:'/px'})
            },
            toCase(){
                this.$router.push({path:'/case'})
            },
            toShowContext(content){
                this.registerComponent().then(component=>{
                    let bannerContent=new component({
                        data:function () {
                            return {
                                txt:content
                            }
                        }
                    });
                    let control={name:'JsOrderAction',component: bannerContent};
                    this.$emit('showOrderAction',control);

                }).catch(e=>{
                        console.log(e);
                    })
            },
            registerComponent(){
                return import(`../xyg/banner/BannerContent.vue`).then(component=>{
                    return Vue.extend(component.default);
                });
            }
        }
    }
</script>

<style scoped>
    .home{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 750px;
        height: 100vh;
    }
    .home::-webkit-scrollbar{
        display:none;/*隐藏滚动条*/
    }

    .home_top{
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        height: 280px;
        width: 710px;
        display: flex;
    }

    .home_menu{
        overflow: scroll;
        width: 750px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .home_menu::-webkit-scrollbar{
        display:none;/*隐藏滚动条*/
    }

    .home_menucontainer{
        margin-left: 20px;
        margin-right: 20px;
        width: 710px;
        display: flex;
        flex-direction: column;
        margin-top: 28px;
    }

    .home_card{
        border-radius: 10px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        width: 710px;
        box-shadow: 0px 2px 20px rgba(100,100,100,0.5);
        z-index: 10;
        /*position: absolute;*/
        height: 560px;
    }

    .home_title{
        margin-left: 60px;
        margin-top: 26px;
        font-size: 30px;
        letter-spacing: 4px;
        font-weight: bolder;
    }
    .home_items{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        margin-top: 34px;
    }

    .home_item{
        flex:1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home_item_container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 150px;
        width: 150px;
        border-radius: 8px;
        box-shadow: 0px 2px 2px rgba(117,164,245,0.5);
    }

    .home_item img{
        width: 100px;
        height: 100px;
    }
    .home_item span{
        margin-top: 4px;
    }

    .home_clear{
        box-shadow:none !important;
    }

    .home_card_item_letterspace{
        margin-top: 70px !important;
    }

    .home_card_letterspace{
        margin-top: 30px;
        margin-bottom: 160px;
    }

</style>
